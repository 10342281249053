<template>
    <section class="container mx-auto" id =  props.requestAmigo._id >
      <div class="flex items-center p-4">
          <div class="rounded-full overflow-hidden imagen-perfil">
          <img  :src="url + props.requestAmigo.user.image" alt="Avatar" class="w-full h-full object-cover" />
        </div>
        <div class="ml-4">
          <div class="usuario-amigo-noti"> {{ props.requestAmigo.user.user }}</div>
          <div class="nombre-amigo-noti mb-1"> {{  props.requestAmigo.user.name }} {{  props.requestAmigo.user.surname }} </div>
          <div class="space-x-1">
            <button class="boton-amigo-noti" @click="acceptRequest"><span>{{ $t('popup-anadirAmigo.add') }}</span></button>
            <button class="boton-amigo-noti" @click="cancelRequest"><span>{{ $t('popup-anadirAmigo.cancelar') }}</span></button>
          </div>
        </div>
      </div>
    </section>
</template> 
  
  <script setup> 
    import { defineProps} from 'vue' 
    import { storeToRefs } from 'pinia';
    import { useAmigosStore } from '@/store/amigos.store';
    import { usePopupStore } from "@/store/popup";


      const msg = usePopupStore();
      const { mensaje } = storeToRefs(msg); 
   
      const props = defineProps({
          requestAmigo:{}
      })


    const url = process.env.VUE_APP_BASE

    function acceptRequest() {

        const aceptarSolicitud = useAmigosStore();
        const { message } = storeToRefs(aceptarSolicitud);


        aceptarSolicitud.acceptRequestFriend(props.requestAmigo._id).then(()=>{
          if ( aceptarSolicitud.ok == true ) {
            aceptarSolicitud.requestAmigos();
            aceptarSolicitud.misAmigos();
            usePopupStore().togglePopupNotificaciones(false);
            mensaje.value = message.value
            msg.toggleInfo(true)
          }}).catch(
              function(error){console.log(error), mensaje.value = JSON.parse(error).message , msg.toggleInfo(true)}
            )
    }

    function cancelRequest() {

      const cancelarSolicitud = useAmigosStore();
      const { message } = storeToRefs(cancelarSolicitud);


      cancelarSolicitud.cancelRequestFriend(props.requestAmigo._id).then(()=>{
        if ( cancelarSolicitud.ok == true ) {
          cancelarSolicitud.requestAmigos();
          usePopupStore().togglePopupNotificaciones(false);
          mensaje.value = message.value
          msg.toggleInfo(true)
        }}).catch(
            function(error){console.log(error), mensaje.value = JSON.parse(error).message , msg.toggleInfo(true)}
          )
    }

   
  
  </script>
  