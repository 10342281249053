<template>
    <div v-if="popupVisible && !popupPassword" class="modal-overlay">
        <div class="modal">
            <div class="close-btn flex flex-row-reverse gap-4">
                <button @click="closePopup">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <form class="form-login" @submit.prevent="login">
                    <div class="form-group">
                        <input type="text" id="username" v-model="form.email" :placeholder="$t('popup.login.user')" required />
                    </div>
                    <div class="form-group">
                        <input type="password" id="password" v-model="form.password" placeholder="PASSWORD" required />
                    </div>
                    <div class="error" v-if="errorShow">{{ errorText }}</div>
                    <div class="botonera">
                        <button type="submit">Log in</button>
                        <router-link to="/registro" @click="closePopup">{{ $t('popup.login.nueva_cuenta') }}</router-link>
                    </div>
                </form>
    
                <div class="olvido-pass text-white">
                    <button @click="openPasswordPopup" class="uppercase">{{ $t('popup.login.olvidaste_contrasena') }}</button>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="popupRegistroDemoVisible" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closePopupRegistro">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <div class="text-white text-center mb-4 text-lg">
                  {{ $t('popup.demo.texto') }}
                </div>
    
                <div class="botonera">
                    <router-link to="/" @click="closePopupRegistro">{{ $t('popup.demo.aceptar') }}</router-link>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="popupRegistroVisible" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closePopupRegistro">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <div class="text-white text-center mb-4 text-lg uppercase">
                    {{ $t('popup.ya_puedes_iniciar.contenido') }}
                </div>
    
                <div class="botonera">
                    <router-link to="/" @click="closePopupRegistro">{{ $t('popup.ya_puedes_iniciar.aceptar') }}</router-link>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="infoModal" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closePopupInfo">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <div class="text-white text-center mb-4 text-lg">
                    <p>{{ mensaje }}</p>
                </div>
    
                <div class="botonera">
                    <button @click="closePopupInfo">Ok</button>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="popupPassword" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closePopupPassword">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="text-white text-center mb-4 text-lg">
                <h1 v-if="!passTextShow" class="mb-3">
                    {{ $t('popup.cambiar_contrasena.title') }}
                </h1>
                <form class="form-login" @submit.prevent="resetPassword">
                    <div class="form-group" v-if="!passTextShow">
                        <input type="text" id="mail" v-model="form.mail" placeholder="Email" required />
                    </div>
    
                    <!-- <div class="error" v-if="showError">{{errorText}}</div> -->
                    <div class="send" v-if="passTextShow">{{ passText }} <br /><br /></div>
                    <div class="botonera" v-if="!passTextShow">
                        <button type="submit">{{ $t('popup.cambiar_contrasena.button') }}</button>
                    </div>
                    <div class="botonera" v-if="passTextShow">
                        <button @click="closePopupPassword">{{ $t('popup.cambiar_contrasena.aceptar') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    <div v-if="popupNotificaciones" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closepopupNotificaciones">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="noti">
                <h1>{{ $t('popup.solicitudes_amigos.title') }}</h1>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 container mx-auto pb-5">
                <div v-if="friendsRequest.length > 0">
                    <div v-for="requestAmigo in friendsRequest" :key="requestAmigo.f_id">
                        <RequestAmigosTarjeta :requestAmigo="requestAmigo"></RequestAmigosTarjeta>
                    </div>
                </div>
                <div class="pt-5 text-white" v-else>
                    <p>{{ $t('popup.solicitudes_amigos.no_hay') }}</p>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="popupVueltasPendientes" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="usePopupStore().togglePopupVueltasPendientes(false)">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <div class="text-white text-center mb-4 text-lg">TIENES VUELTAS PENDIENTES.</div>
    
                <div class="text-white text-center mb-4 text-md"></div>
    
                <div class="botonera">
                    <button @click="enviarDatos()">SI</button>
                    <button @click="usePopupStore().togglePopupVueltasPendientes(false)">NO</button>
                </div>
            </div>
        </div>
    </div>
    
    <div v-if="popupCondiciones" class="modal-overlay">
        <div class="modal">
            <div class="close-btn">
                <button @click="closepopupCondiciones">
              <img src="@/assets/img/cerrar-modal.png" />
            </button>
            </div>
    
            <div class="modal-content">
                <div class="text-white text-center mb-4 text-lg">
                    Declaro que estoy de acuerdo con las <a href="/condiciones-generales" target="_blank" class="link">condiciones generales de contratación</a>. He leído y acepto la <a href="/politica-privacidad" target="_blank" class="link">política de privacidad</a>
                </div>
                <div class="text-white text-center mb-4 text-lg">
                    <input class="custom-input-container" v-model="code" placeholder="CÓDIGO PROMOCIONAL" />
                </div>
                <div class="botonera">
                    <button @click="planTiendaClick">Aceptar</button>
                    <router-link to="/tienda" @click="closepopupCondiciones">Denegar</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { usePopupStore } from "@/store/popup";
import { useTiendaStore } from "@/store/tienda.store";
import { usePasswordStore } from "@/store/password.store";
import { useAuthStore } from "@/store/auth.store";
import { useAmigosStore } from "@/store/amigos.store";
import { useVueltaStore } from "@/store/vuelta.store";
import RequestAmigosTarjeta from "./RequestAmigosTarjeta.vue";
import { planTiendaClick } from '@/helpers'
import router from "@/router";

//validar si el popup debe estar visible o no segun sea true/false
const popupVisible = computed(() => usePopupStore().popupVisible);
const popupRegistroVisible = computed(() => usePopupStore().popupRegistroVisible);
const infoModal = computed(() => usePopupStore().infoModal);
const msg = usePopupStore();
const { mensaje } = storeToRefs(msg);
const tienda = useTiendaStore();
const { code } = storeToRefs(tienda);
const popupPassword = computed(() => usePopupStore().popupPassword);
const popupNotificaciones = computed(() => usePopupStore().popupNotificaciones);
const popupCondiciones = computed(() => usePopupStore().popupCondiciones);

const openPasswordPopup = () => {
    usePopupStore().togglePopupPassword(true);
};

const closePopupPassword = () => {
    usePopupStore().togglePopupPassword(false);
    passTextShow.value = false;
};

//cerrar popup
const closePopup = () => {
    usePopupStore().togglePopup(false);
    mensaje.value = "";
};

const closepopupCondiciones = () => {
    usePopupStore().togglePopupCondiciones(false);
};

//cerrar popup Registro
const closePopupRegistro = () => {
    usePopupStore().toggleRegistro(false);

    mensaje.value = "";
};

//cerrar popup Info
const closePopupInfo = () => {
    usePopupStore().toggleInfo(false);
    mensaje.value = "";
    console.log(router.currentRoute.value);
    if (router.currentRoute.value.name == "home") router.push("/");
};

const closepopupNotificaciones = () => {
    usePopupStore().togglePopupNotificaciones(false);
};

//Variables para Formulario Login
const errorShow = ref(false);
const errorText = ref("");

const passTextShow = ref(false);
const passText = ref(null);

const form = ref({
    email: "",
    password: "",
    mail: "",
});

// PopUp vueltas pendientes
function vueltas_pendientes() {
    const vueltaStore = useVueltaStore();
    vueltaStore.vueltas_pendientes();
}

// Login Usuario
function login() {
    errorShow.value = false;
    const authStore = useAuthStore();

    const { email, password } = form.value;

    authStore
        .login(email, password)
        .then(() => {
            errorShow.value = false;
            // Consultamos si tiene vueltas pendientes de alguna invitacion
            vueltas_pendientes();
            ///////////////////////////////////
            closePopup();
        })
        .catch((error) => {
            console.log(error);
            errorShow.value = true;
            errorText.value = JSON.parse(error).message;
        });
}

function resetPassword() {
    const pass = usePasswordStore();

    const { mail } = form.value;

    const { message } = storeToRefs(pass);

    pass.forgotPassword(mail);

    watch(() => message.value, (newMessage) => {
        passText.value = newMessage;
        passTextShow.value = true;
    });

}

const dataAmigosRequest = useAmigosStore();

const { friendsRequest } = storeToRefs(dataAmigosRequest);
</script>

<style scoped>
.link {
    @apply text-gris;
    text-decoration: none;
}

.custom-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: Brandon;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding: 14px 22px;
    margin-bottom: 15px;
    outline: none;
    border-radius: 0.25rem;
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(220 227 220 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(59 60 67 / var(--tw-text-opacity));
    text-transform: uppercase;
}
</style>
